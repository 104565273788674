.home-container {
    padding: 20px;
    text-align: left;
    margin-top: 0px;
    font-size: 25pt;
}

.heading-left {
    text-align: left;
}

.heading-right {
    text-align: right;
}